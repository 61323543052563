<template>
    <div class="shiftHandoverReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()">
                    <div class="title">
                        <span>今天 <br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        :editable="false"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="bnt-search" @click="ShiftChangeReport()">查询</div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                <div class="bnt" @click="print()"><i class="iconfont icon-dayin"></i>打印报表</div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="left-table">
                <div class="table-box grey-table" >
                    <el-table ref="tableEl" id="shitableEl" class="el-table--scrollable-y"  border  :data="tableList"  style="width: 100%;height:100%"   v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="Rpt_Date" label="营业日期" min-width="75"></el-table-column>
                        <el-table-column prop="Shift_No" label="交班单号" min-width="90"></el-table-column> 
                        <el-table-column prop="Shift_User" label="交班人" min-width="50"></el-table-column> 
                        <el-table-column prop="Shift_EndTime" label="交班时间" min-width="80"></el-table-column>
                        <el-table-column prop="Shift_PosName" label="站点" min-width="85"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="50">
                            <template #default="scope">
                                <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="right-view">
                <div class="printExample" >
                    <div class="scroll-box" v-scroll-anime="{up:'page-up',down:'page-down'}" v-mouse-scroll>
                        <div v-html="Print_Html"></div>
                    </div>
                </div>
                <div class="page-bnt clearfix">
                    <div class="bnt page-up" ref="page-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt page-down" ref="page-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
     </div>
</template>

<script>
import { newGuid,printView } from '../../../../../common'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'
/**交班表 */
export default {
    components:{checkUserButtonModel},
    name:'shiftHandoverReport',
    data(){
        return {
            /**表格数据 */
            tableList:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            queryType:false, //true: 日期范围    false：当前营业数据
            /**详情数据 */
            shiftContents:[],
            /**选择的交班表信息 */
            printBodies:'',
            /**打印机设备 */
            Printer:{},
            userInfo:[],
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{},
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.userInfo=this.$auth.getUserInfo();
            this.tempUserId='',this.tempOperatorName=''
            this.queryType=false;
            
            this.nowadays();
            
        })
    },
    computed:{
        /** 打印数据展示 html **/
        Print_Html(){
            return printView(this.shiftContents);
        },
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        }
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            let xlsxParam = { raw: true };
            var fix = document.querySelector('.el-table__fixed-right')
            var wb
            if (fix) {
                wb = XLSX.utils.table_to_book(document.querySelector('#shitableEl').removeChild(fix),xlsxParam)
                document.querySelector('#shitableEl').appendChild(fix)
            } else {
                wb = XLSX.utils.table_to_book(document.querySelector('#shitableEl'),xlsxParam)
            }
            /* 获取二进制字符串作为输出 */
            var wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'array'
            })
            try {
                FileSaver.saveAs(
                new Blob([wbout], { type: 'application/octet-stream' }),'交班表.xlsx')
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e, wbout)
            }
            return wbout
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1)
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")]
            this.ShiftChangeReport()
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
        /**获取交班报表信息 */
        ShiftChangeReport(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('param:'+ JSON.stringify(param))
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.post("Bestech.CloudPos.ShiftChangeReport",param,false).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.shiftContents=[]
                    this.tableList = d.ResponseBody
                    if(this.tableList){
                        this.tableList.map(item=>{
                            item.Rpt_Date = new Date(item.Rpt_Date).Format("yyyy-MM-dd")
                            item.Shift_EndTime = new Date(item.Shift_EndTime).Format("yyyy-MM-dd hh:mm:ss")
                        })
                    }
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                     this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        /**查看 */
        handleClick(item){
            this.shiftContents = item.Shift_Contents;
            this.Printer=item.Printer;
        },
        /**打印 */
        print(){
            if(this.Printer){
                let printJson=[ {
                    PrintBodies:this.shiftContents,
                    "Print_Num":1,
                    "Print_Serial": "",
                    "Print_TemplateID": "",
                    "Printer_Name": this.Printer.Printer_Name,
                    "Printer_Type": this.Printer.Printer_Type,
                }];
                this.$webBrowser.posPrint(printJson);
                this.$message.success("已发送打印指令");
            }else{
                this.$message.success("未获取到打印设备信息");
            }
        },
        /**权限验证返回 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.tempUserId = date.User_ID
            this.tempOperatorName = date.Login_Name
            this.ShiftChangeReport()
        },
    }
}
</script>

<style lang="scss">
@import './shiftHandoverReport.scss'
</style>